<template>
  <div class="container-fluid">
    <h1>{{ txttitre }}</h1>
    <div class="row">
      <div class="col-md-offset-2 col-md-3">
        <label for="dt">Date</label>
        <div class="input-group">
          <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
          <datepicker language="fr" v-model="inter.dt"></datepicker>
        </div>
      </div>

      <div class="col-md-offset-1 col-md-3">
        <label for="prenom">Sujet</label>
        <div class="input-group">
          <span class="input-group-addon"
            ><i class="fa fa-newspaper-o"></i
          ></span>
          <input
            type="text"
            v-model="inter.sujet"
            class="form-control"
            id="sujet"
            placeholder="Sujet"
          />
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="form-group">
        <div class="col-md-offset-1 col-md-10">
          <label for="commande.commentaire">Commentaire :</label>
          <textarea
            class="form-control"
            rows="5"
            v-model="inter.remarque"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-offset-2 col-md-3">
        <button type="button" class="btn btn-primary" @click="add" v-if="idMaintenance!=0">
          {{ txtfct }}
        </button>
      </div>
    </div>
    <br />
    <div v-if="inter.image" class="row">
      <div class="form-group">
        <div class="col-md-offset-1 col-md-10">
            <a :href="getimage(inter.image)" target="_blank"><img :src="getimage(inter.image)" width="300" /></a>
        </div>
      </div>
    </div>
    <div v-if="photos.length>0" class="row">
      <div class="form-group" v-for="(photo,index) in photos" :key="index">
        <div class="col-md-offset-1 col-md-10">
          <span @click="viewphoto(photo.photo)"><img :src="photo.photo" width="300" /></span>
        </div>
      </div>
    </div>     
    <div class="alert alert-danger" v-if="err">
      <strong><i class="fa fa-warning"></i> Erreur!</strong> {{ msg }}
    </div>
  </div>
</template>

<script>
import store from "../store";
import { executeApi, getPrefix, getPrefixS2T, selectApi } from "../lib/api.js";
import { fla } from "../lib/fct.js";
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
  },
  store: store,
  data() {
    return {
      id: this.$route.params.id,
      idFeu: this.$route.params.feu,
      idReg: this.$route.params.reg,
      idMaintenance: 0,
      err: false,
      msg: "",
      inter: {
        dt: moment().format("MM/DD/YYYY"),
        sujet: "",
        remarque: "",
      },
      photos: []
    };
  },
  computed: {
    txttitre: function () {
      return this.id == 0
        ? "Ajouter une intervention"
        : "Modifier l'intervention : " + this.id;
    },
    txtfct: function () {
      return this.id == 0 ? "Ajouter" : "Modifier";
    },
  },
  mounted() {
    if (store.state.typeUser == 0) {
      this.$router.push({ name: "login" });
    }

    if (this.id != 0) {
      selectApi(this,"SELECT dt,sujet,remarque,image FROM interpiste WHERE id=" + this.id
      ).then((response) => {
        this.count = response.data.count;
        if (this.count >= 0) {
          this.inter = response.data.result[0];
        }
      })

      selectApi(this,"SELECT photo,ext FROM photoaction WHERE idinter=" + this.id
      ).then((response) => {
        this.count = response.data.count;
        if (this.count >= 0) {
          this.photos = response.data.result;
          this.photos.forEach((photo) => {
            photo.photo = "data:image/" + photo.ext.replace('.', '') + ";base64," + photo.photo
          })
        }
      })

    }

    let idPiste = store.state.piste;
    let sql = "SELECT id from maintenance WHERE type=4 AND idpiste=" + idPiste + " ORDER BY id DESC LIMIT 1";
      selectApi(this,sql).then((response) => {
        this.count = response.data.count;
        if (this.count > 0) {
          this.idMaintenance = response.data.result[0].id;
        } else {
        sql = "INSERT INTO maintenance SET type=4,dt=NOW(),idpiste=" + idPiste;
        executeApi(this, sql).then(
          (response) => {
            this.idMaintenance = response.data.last;
            },
          (response) => {
              this.idMaintenance = 0;
          })
        }
      })

  },
  methods: {
    getimage: function (img) {
      return getPrefixS2T() + "/app/photo/" + img;
    },
    viewphoto(b64) {
      var image = new Image();
      image.src = b64
      var w = window.open("")
      w.document.write(image.outerHTML)
    },
    add() {
      if (this.inter.dt == "" || this.inter.sujet == "") {
        this.msg = "Les champs ne peuvent pas être vides.";
        this.err = true;
      } else {
        this.err = false;
        let dt = moment(this.inter.dt);
        dt = dt.isValid() ? dt.format("YYYY-MM-DD") : "0000-00-00";
        let modif =
          "dt='" +
          dt +
          "',sujet='" +
          fla(this.inter.sujet) +
          "',remarque='" +
          this.inter.remarque +
          "'";
        let sql = "";
        if (this.id == 0) {
          sql = "INSERT INTO interpiste SET client=1,idfeu="+this.idFeu+",idregulateur="+this.idReg+",idmaintenance=" + this.idMaintenance + "," + modif;
        } else {
          sql = "UPDATE interpiste SET " + modif + " WHERE id=" + this.id;
        }
        executeApi(this, sql).then(
          (response) => {
            this.$router.go(-1);
          },
          (response) => {
            this.msg = "Erreur d'enregistrement.";
            this.err = true;
          }
        );
      }
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.sel {
  font-size: 10px;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
